import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Features() {
  return (
    <div className="container text-center">
      <h3 className="mb-3">Key Features</h3>
      <div className="row justify-content-around">
        <div className="card mb-2" style={{ width: '18rem' }}>
          <div className="card-body">
            <FontAwesomeIcon
              icon={['fas', 'shield-alt']}
              size="8x"
              color="#5b66f3"
              className="align-middle"
            />
            <h3 className="card-title m-1">Buyer Protection</h3>
            <p className="mb-0">
              PC Swaps Buyer Protection covers any item that is damaged or not
              as advertised in the listing.
            </p>
            <a
              className="btn btn-link"
              target="_blank"
              rel="noreferrer"
              href="https://blog.pcswaps.com/what-is-buyer-protection/"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="card mb-2" style={{ width: '18rem' }}>
          <div className="card-body">
            <FontAwesomeIcon
              icon={['fas', 'user-shield']}
              size="8x"
              color="#5b66f3"
              className="align-middle"
            />
            <h3 className="card-title m-1">Seller Protection</h3>
            <p className="mb-0">
              If the buyer has not marked the item accepted within 3 days, then
              we automatically release the funds to you.
            </p>
            <a
              className="btn btn-link"
              target="_blank"
              rel="noreferrer"
              href="https://blog.pcswaps.com/how-i-am-protected-as-a-seller/"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="card mb-2" style={{ width: '18rem' }}>
          <div className="card-body">
            <FontAwesomeIcon
              icon={['fas', 'shipping-fast']}
              size="8x"
              color="#5b66f3"
              className="align-middle"
            />
            <h3 className="card-title m-1">Easy Shipping</h3>
            <p className="mb-0">
              All you need to do is print the label, attach it to the package,
              and drop it off at the correct courier.
            </p>
            <a
              className="btn btn-link"
              target="_blank"
              rel="noreferrer"
              href="https://blog.pcswaps.com/how-do-i-print-and-ship-an-item/"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="card mb-2" style={{ width: '18rem' }}>
          <div className="card-body">
            <FontAwesomeIcon
              icon={['fas', 'money-bill-wave']}
              size="8x"
              color="#5b66f3"
              className="align-middle"
            />
            <h3 className="card-title m-1">Lowest Fees</h3>
            <p className="mb-0">
              Our selling fee is 8% TOTAL. No hidden fees or extra costs.
            </p>
            <a
              className="btn btn-link"
              target="_blank"
              rel="noreferrer"
              href="https://blog.pcswaps.com/what-are-the-fees/"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
